import { YStack } from '@my/ui'
import '@tamagui/core/reset.css'
import '@tamagui/font-inter/css/400.css'
import '@tamagui/font-inter/css/700.css'
import { NextThemeProvider } from '@tamagui/next-theme'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { Provider } from 'app/provider'
import { AuthProviderProps } from 'app/provider/auth'
import { api } from 'app/utils/api'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import { DefaultSeo } from 'next-seo'
import 'raf/polyfill'
import { ReactElement, ReactNode, memo, useEffect, useRef } from 'react'
import type { SolitoAppProps } from 'solito'
require('../public/reset.css')

if (process.env.NODE_ENV === 'production') {
  require('../public/tamagui.css')
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
  //https://stackoverflow.com/questions/59124463/nextjs-how-to-not-unmount-previous-page-when-going-to-next-page-to-keep-state
  retainable?: boolean
}

const APP_NAME = 'MyDreamBoy'
const APP_DESCRIPTION = 'Create The Man Of Your Dreams!'

function MyApp({
  Component,
  pageProps,
}: SolitoAppProps<{ initialSession: AuthProviderProps['initialSession'] }> & {
  Component: { retainable?: boolean }
}) {
  // reference: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()
  const retainedComponents = useRef({})

  const isRetainableRoute = Component.retainable

  // Add Component to retainedComponents if we haven't got it already
  if (isRetainableRoute && !retainedComponents.current[router.asPath]) {
    const MemoComponent = memo(Component)
    retainedComponents.current[router.asPath] = {
      component: getLayout(<MemoComponent {...pageProps} />),
      scrollPos: 0,
    }
  }

  // Save the scroll position of current page before leaving
  const handleRouteChangeStart = () => {
    if (isRetainableRoute) {
      retainedComponents.current[router.asPath].scrollPos = window.scrollY
    }
  }

  // Save scroll position - requires an up-to-date router.asPath
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router.asPath])

  // Scroll to the saved position when we load a retained component
  useEffect(() => {
    if (isRetainableRoute) {
      setTimeout(() => {
        window.scrollTo(0, retainedComponents.current[router.asPath].scrollPos)
      }, 33)
    }
  }, [Component, pageProps])

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.svg" />
        <meta name="application-name" content={APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={APP_NAME} />
        <meta name="description" content={APP_DESCRIPTION} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#050505" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.mydreamboy.com/" />
      </Head>
      <DefaultSeo
        title="Pick your AI boyfriend | Meet Your Dream Boy"
        description="MyDreamBoy is the best AI Porn Generator for Male. Our cutting-edge AI Porn Generator offers a seamless way to create highly realistic AI-generated pornographic art and images, featuring hundreds of NSFW/SFW tags for personalized content creation."
        additionalMetaTags={[
          {
            property: 'keywords',
            content:
              'ai boyfriend, ai boys, ai boy, ai gay, ai man, ai men, ai male, ai daddy, ai generated, nsfw ai image generator, ai porn, ai sex, ai sexy, ai nude, ai generated porn, ai generator, ai image generator, adult ai, gay porn, ai porn generator, ai images, best ai porn prompts, custom ai boy, ai porn images, boy ai generator, aiboy, create boy ai images, boy ai image generator, aiporn, make porn, ai porn pictures, ai porn free, realistic ai, undress ai',
          },
        ]}
      />
      <NextThemeProvider forcedTheme="dark" enableSystem={false} defaultTheme="dark">
        <Provider initialSession={pageProps.initialSession}>
          <YStack style={{ display: isRetainableRoute ? 'flex' : 'none' }} flex={1}>
            {Object.entries(retainedComponents.current).map(([path, c]) => (
              <YStack
                key={path}
                style={{ display: router.asPath === path ? 'flex' : 'none' }}
                flex={1}
              >
                {(c as any).component}
              </YStack>
            ))}
          </YStack>
          {!isRetainableRoute && getLayout(<Component {...pageProps} />)}
        </Provider>
      </NextThemeProvider>
      <ProgressBar
        height="3px"
        color="hsl(206, 100%, 50.0%)"
        options={{ showSpinner: true }}
        shallowRouting
      />
      <Script
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        src="https://um.mydreamboy.com/script.js"
        data-website-id={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? '04d1ec70-265a-4395-b03f-f2da6108fb7f'
            : '9a8599ed-3095-4689-8e62-2ddac7ddabc5'
        }
      />
      <SpeedInsights />
      {/* <!-- Trackdesk tracker begin --> */}
      <Script async src="https://cdn.trackdesk.com/tracking.js" />
      <Script
        dangerouslySetInnerHTML={{
          __html:
            '(function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");trackdesk("mydreamboy", "click");',
        }}
      />
      {/* <!-- Trackdesk tracker end --> */}
    </>
  )
}

export default api.withTRPC(MyApp)
